<div class="overlay" [@fadeAnimation] [routerLink]="['./..']"></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Push to Midas</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      [routerLink]="['./..']"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div [style.width.%]="100" class="padding-y">
      <h4>Midas Integration Details</h4>
      <a
        class="link"
        [href]="'/app/' + path + '/' + id + '?tab=journal'"
        target="_blank"
      >
        See Errors in Journal <i class="material-icons">open_in_new</i>
      </a>
      <form [formGroup]="mortgagePushToMidasForm" class="np-form">
        <p class="label">Push to Midas</p>
        <hfc-checkbox-list
          [map]="PUSH_TO_MIDAS_TYPE"
          formControlName="type"
        ></hfc-checkbox-list>
        <p class="label text-error" *ngIf="selectedType.value === 'FULL'">
          Only initiate a full re-push if advised by MAB
        </p>
      </form>
    </div>
  </div>

  <div class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Push"
      (onClick)="onSubmit()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      [routerLink]="['./..']"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
