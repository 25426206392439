import { Component } from "@angular/core";
import {
  fadeAnimation,
  sidepaneMove,
} from "../../../shared/animations/sidepane-animation";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PUSH_TO_MIDAS_TYPE } from "../../../../../../projects/client/src/app/fact-find/enums.module";
import { MortgagesService } from "../../../modules/mortgages/mortgages.service";
import { ProtectionDashboardService } from "../../../modules/protection/components/protection-dashboard/protection-dashboard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { ProtectionApiService } from "../../../services/protection-api.service";
import { ToasterService } from "../../../services/toaster.service";

@Component({
  selector: "hf-push-to-midas",
  templateUrl: "./push-to-midas.component.html",
  styleUrls: [
    "./push-to-midas.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
})
export class PushToMidasComponent {
  public mortgagePushToMidasForm: FormGroup;
  public PUSH_TO_MIDAS_TYPE = PUSH_TO_MIDAS_TYPE;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly mortgagesService: MortgagesService,
    private readonly protectionDashboardService: ProtectionDashboardService,
    private readonly mortgagesApiService: MortgageApiService,
    private readonly protectionApiService: ProtectionApiService,
    private readonly toasterService: ToasterService,
  ) {
    this.mortgagePushToMidasForm = this.fb.group({
      type: ["STANDARD", Validators.required],
    });
  }

  get path(): "protection" | "mortgages" {
    return this.isOnProtectionPage ? "protection" : "mortgages";
  }

  get id(): string {
    return this.path === "protection"
      ? this.protectionData?.id
      : this.mortgageData?.id;
  }

  get selectedType() {
    return this.mortgagePushToMidasForm.get("type");
  }

  get mortgageData() {
    return this.mortgagesService.data;
  }

  get protectionData() {
    return this.protectionDashboardService.protectionDashboardDTO;
  }

  private get isOnProtectionPage(): boolean {
    return this.router.url.includes("protection");
  }

  onSubmit(): void {
    const selectedType = this.selectedType.value as PUSH_TO_MIDAS_TYPE;

    if (this.isOnProtectionPage) {
      this.protectionApiService
        .pushToMidas(selectedType, this.id)
        .subscribe(() => this.onSuccessPushToMidas());
    } else {
      this.mortgagesApiService
        .pushToMidas(selectedType, this.id)
        .subscribe(() => this.onSuccessPushToMidas());
    }
  }

  private onSuccessPushToMidas(): void {
    this.toasterService.callToaster({
      severity: "info",
      detail: `Manual push attempted - check the<br />journal in a few minutes`,
    });
    this.router.navigate(["./.."], { relativeTo: this.activatedRoute });
  }
}
