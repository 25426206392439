import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-mortgage-status-badge',
  templateUrl: './mortgage-status-badge.component.html',
  styleUrls: ['./mortgage-status-badge.component.scss']
})
export class MortgageStatusBadgeComponent {
  @Input()
  public mortgage: any;
}
