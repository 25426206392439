import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { delay, map } from "rxjs/operators";
import { toLocalDateISO } from "../../../../projects/client/src/app/shared/functions/to-local-date-iso";
import { UserSimple } from "../models";
import { ApplicationListDTO } from "../models/application";
import { ApplicationCreateDTO } from "../models/application-create";
import { ApplicationStatusDTO } from "../models/application-status";
import { Attachment } from "../models/attachment";
import { ContactAttemptDTO } from "../models/contact-attempt.dto";
import { JournalDTO, JournalWithDocumentDTO } from "../models/journal";
import { JournalWithReasonDTO } from "../models/journal-with-reason.dto";
import { Lender } from "../models/lender";
import {
  MortgageDashboard,
  MortgageDashboardHandover,
} from "../models/mortgage-dashboard";
import { MortgageImportedDataDTO } from "../models/mortgage-imported-data.dto";
import { NewComplianceHandoverBudgetDTO } from "../models/new-compliance-handover-budget.dto";
import { NewComplianceHandoverObjectiveDTO } from "../models/new-compliance-handover-objective.dto";
import { NewComplianceHandoverPreferenceDTO } from "../models/new-compliance-handover-preference.dto";
import {
  NewComplianceHandoverRecommendedDTO,
  PropCode,
} from "../models/new-compliance-handover-recommended.dto";
import {
  NewComplianceHandoverViewDTO,
  NewOrLegacyComplianceHandoverViewDTO,
} from "../models/new-compliance-handover-view.dto";
import { Solicitor } from "../models/solicitor";
import { SuitabilityLetterDTO } from "../models/suitability-letter.dto";
import { TableParams } from "../shared/table-utils/table-settings.service";
import {
  APPLICATION_STATE,
  CLIENT_CATEGORY,
  COMPLIANCE_HANDOVER_STATE,
  DEBT_CONSOLIDATION_EXPLANATION,
  EditChangesType,
  FACT_FIND_STATE,
  HELP_TO_BUY_STATUS,
  MORTGAGE_ACTIONS,
  MORTGAGE_OBJECTIVE,
  MORTGAGE_PRODUCT_DATA_TYPE,
  MORTGAGE_PRODUCT_END_TYPE,
  MORTGAGE_STATE_TYPE,
  MORTGAGE_WAITING_ON,
  OFFER_QUALIFICATION_STATUS,
  USER_TYPE,
} from "../utils/variables.data";
import { ListResult, Result } from "./api-models";
import { ApiService } from "./api.service";
import { Environment } from "./environment.service";
import { NotProceedingReasonsDTO } from "./not-proceeding-reasons.service";
import { PUSH_TO_MIDAS_TYPE } from '../../../../projects/client/src/app/fact-find/enums.module';

export interface MortgageListItem {
  id: string;
  createdDate: Date;
  client: string;
  introducer: string;
  advisor: string;
  nextAction: MORTGAGE_ACTIONS;
  waitingOn: MORTGAGE_WAITING_ON;
  state: MORTGAGE_STATE_TYPE;
  contactAttempts: number;
  advisorPhoto: Attachment;
  introducerLogo: Attachment;
  clientCategory: CLIENT_CATEGORY;
  selfReferral: boolean;
  reference: string;
  tasksAll: number;
  tasksDue: number;
  lastAction: Date;
  lastActionBy: string;
  lastActionByUsertype: USER_TYPE;
  activeApplications: number;
  yourkeysReferenceId?: string;

  appointmentDate: Date;
  proceedingState: "WAITING" | "PROCEEDING" | "NOT_PROCEEDING";

  factFindState: FACT_FIND_STATE;
  applicationState: APPLICATION_STATE;

  submittedDate: Date;
  lenderReference: string;
  lender: string;
  helpToBuyStatus: HELP_TO_BUY_STATUS;
  valuationState: "INSTRUCTED" | "BOOKED" | "RECEIVED";

  offerExpiryDate: Date;

  completionDate: Date;
  productEndDate: Date;
}

export interface JournalBasic {
  mortgageId: string;
  messageToClient: string;
  internalNotes?: string;
}

export interface HelpToBuyRegion {
  id: string;
  name: string;
}

export interface HelpToBuy {
  status: HELP_TO_BUY_STATUS;
  submittedDate: string;
  amendmentDate: string;
  dateOfIssue: string;
  regionId: string;
  cancelReason: string;
  journal?: JournalWithDocumentDTO;
}

export interface OfferQualification {
  status: OFFER_QUALIFICATION_STATUS;
  qualifiedUpTo: number;
  purchasePrice: number;
  helpToBuyLoan: number;
  depositAmount: number;
  mortgageAmount: number;
  clientProceeding: boolean;
  sendFactFind: boolean;
  appointment: any;
  journal: JournalDTO;
}

export interface RequalificationRequest {
  desiredQualification: number;
  comments: string;
}

export interface YearsMonths {
  years: number;
  months: number;
}

export interface ApplicantIncome {
  applicant: UserSimple;
  incomes: { description: string; amount: number }[];
  total: number;
}

export interface NetMonthlyIncome {
  applicantId: string;
  amount: number;
}

export interface ComplianceHandoverData {
  id: string;
  currentSituation: string;
  objective: MORTGAGE_OBJECTIVE;
  otherObjective: string;
  clientMortgageNeed: string;
  choiceOfLenderExplanation: string;
  incomeCalculationExplanation: string;
  feesToLoan: boolean;
  mortgageBeyondRetirement: boolean;
  debtConsolidation: boolean;
  debtConsolidationExplanation: DEBT_CONSOLIDATION_EXPLANATION;
  otherDebtConsolidationExplanation: string;
  interestOnly: boolean;
  interestOnlyExplanation: string;
  knownIncomeChanges: boolean;
  knownIncomeChangesExplanation: string;
  lender: Lender;
  lenderReference?: string;
  loanAmount: number;
  type: MORTGAGE_PRODUCT_DATA_TYPE;
  mortgageTerm: YearsMonths;
  rate: number;
  productCode: string;
  endDateType: MORTGAGE_PRODUCT_END_TYPE;
  endDate: Date;
  endYearsMonths: YearsMonths;
  netMonthlyIncomes: NetMonthlyIncome[];
  kfi: Attachment;
  recommendationEvidence: Attachment;
  applicantsIncomes: ApplicantIncome[];
  state: COMPLIANCE_HANDOVER_STATE;
  reasonForChange?: string;
  currentType: MORTGAGE_PRODUCT_DATA_TYPE;
  currentMortgageTerm: YearsMonths;
  currentRate: number;
  handover: MortgageDashboardHandover;
}

export interface EditChangeDTO {
  field: string;
  from: string;
  to: string;
}

export interface EditChangesGroupDTO {
  createdDate: Date;
  executor: UserSimple;
  reason: string;
  type: EditChangesType;
  changes: EditChangeDTO[];
}

export interface ComplianceHandoverDataWithHistory
  extends ComplianceHandoverData {
  changeHistory: EditChangesGroupDTO[];
}

export interface CreditSearchOptOutApplicant {
  id: string;
  fullName: string;
  creditSearchOptOut: boolean;
}

export interface CreditSearchOptOutDTO {
  applicants: CreditSearchOptOutApplicant[];
}

export interface RemortgageDTO {
  mortgageId: string;
  advisor: UserSimple;
  propertyValue: string;
  clientCategory: string;
  clientType: string;
  clientScheme: string;
  contactByEmail: boolean;
  contactByPhone: boolean;
  contactByMessage: boolean;
  notes: string;
}

export interface SwitchClientCategoryDTO {
  clientCategory: CLIENT_CATEGORY;
}

export type MortgageQuickFilter =
  | "INITIAL"
  | "ACTIVE"
  | "SUBMITTED"
  | "OFFERED"
  | "COMPLETED"
  | "NOT_PROCEEDING"
  | "INTRODUCER_ACTIVE"
  | "INTRODUCER_LIVE"
  | "INTRODUCER_COMPLETED"
  | "INTRODUCER_NOT_PROCEEDING";

export interface ListMortgagesParams extends TableParams {
  quickFilter: MortgageQuickFilter;
  assignedToMe: boolean;
}

export interface CreateApplicationInfoDTO {
  handovers: MortgageDashboardHandover[];
}

export interface CommissionDTO {
  commission: number;
}

export interface EditTargetCompletionDateDTO {
  targetCompletionDate: Date;
}

export interface EditTargetExchangeDateDTO {
  targetExchangeDate: Date;
}

export interface UpdateIntroducerDto {
  mortgageId: string;
  introducerId: string;
  divisionId: string;
  salesAdvisorId: string;
}

export interface GetPropCodesResult {
  items: PropCode[];
}

export function fixDatesInNewClientSheet(dto: NewComplianceHandoverViewDTO): NewComplianceHandoverViewDTO {
  if (dto.recommended?.endDate) {
    dto.recommended.endDate = moment(dto.recommended.endDate).toDate();
  }

  if (dto.recommended?.illustrationDate) {
    dto.recommended.illustrationDate = moment(dto.recommended.illustrationDate).toDate();
  }

  return dto;
}

@Injectable()
export class MortgageApiService {
  private readonly API_NAME: string = "mortgage";

  constructor(
    private apiService: ApiService,
    private environment: Environment,
    private http: HttpClient
  ) {}

  public listMortgages(
    params: ListMortgagesParams
  ): Observable<ListResult<MortgageListItem>> {
    return this.apiService.get(this.API_NAME, "listMortgages", { params });
  }

  public addMortgageHeronUser(body) {
    return this.apiService
      .post(this.API_NAME, "addMortgageHeronUser", body)
      .toPromise();
  }

  public addMortgageIntroducer(body) {
    return this.apiService
      .post(this.API_NAME, "addMortgageIntroducer", body)
      .toPromise();
  }

  public getDashboardData(mortgageId: string): Observable<MortgageDashboard> {
    const params = { mortgageId };
    const mapDate = (data: MortgageDashboard) => {
      if (data.appointment && data.appointment.appointmentDate) {
        data.appointment.appointmentDate = moment(
          data.appointment.appointmentDate
        ).toDate() as any;
      }
      if (data.targetExchangedDate) {
        data.targetExchangedDate = moment(
          data.targetExchangedDate
        ).toDate() as any;
      }
      if (data.targetCompletionDate) {
        data.targetCompletionDate = moment(
          data.targetCompletionDate
        ).toDate() as any;
      }
      return data;
    };
    const http = this.apiService
      .get(this.API_NAME, "getDashboardData", { params })
      .pipe(map(mapDate));

    if ((window as any).delayMortgage) {
      return http.pipe(delay(10000));
    }

    return http;
  }

  public editMortgage(mortgageId: string, mortgageData) {
    return this.apiService
      .post(this.API_NAME, "editMortgage", mortgageData, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public addContactAttempt(
    contactAttemptDTO: ContactAttemptDTO,
    mortgageId: string
  ) {
    if (contactAttemptDTO.appointment)
      contactAttemptDTO.appointment.appointmentDate = toLocalDateISO(
        contactAttemptDTO.appointment.appointmentDate,
        false
      );
    return this.apiService
      .post(this.API_NAME, "addContactAttempt", contactAttemptDTO, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public assignAdvisor(advisorId: string, mortgageId: string) {
    const params = { mortgageId, advisorId };

    return this.apiService
      .post(this.API_NAME, "assignAdvisor", undefined, { params })
      .toPromise();
  }

  public async getMortgageApplicants(
    mortgageId: string
  ): Promise<UserSimple[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getMortgageApplicants", { params: { mortgageId } })
      .toPromise<ListResult<UserSimple>>();
    return result.items;
  }

  public async requestFactFind(
    mortgageId: string,
    body: JournalBasic
  ): Promise<void> {
    body.internalNotes || delete body.internalNotes;

    return this.apiService
      .post(this.API_NAME, "requestFactFind", body, { params: { mortgageId } })
      .toPromise();
  }

  public async createApplication(
    mortgageId: string,
    applicationBody: ApplicationCreateDTO
  ): Promise<void> {
    return this.apiService
      .post(this.API_NAME, "createApplication", applicationBody, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async setApplicationStatus(
    applicationId: string,
    status: ApplicationStatusDTO
  ): Promise<void> {
    return this.apiService
      .post(this.API_NAME, "setApplicationStatus", status, {
        params: { applicationId },
      })
      .toPromise();
  }

  public async getAllLenders(): Promise<Lender[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getAllLenders")
      .toPromise<ListResult<Lender>>();

    return result.items;
  }

  public arrangeAppointment(mortgageId: string, body) {
    return this.apiService
      .post(this.API_NAME, "arrangeAppointment", body, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async assignCaseProgression(params: {
    mortgageId: string;
    caseProgressionId: string;
    applicationId?: string;
  }): Promise<void> {
    return this.apiService
      .post(this.API_NAME, "assignCaseProgression", undefined, { params })
      .toPromise();
  }

  public async getMortgageApplications(
    mortgageId: string
  ): Promise<ApplicationListDTO[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getMortgageApplications", { params: { mortgageId } })
      .toPromise<ListResult<ApplicationListDTO>>();

    return result.items;
  }

  public async getSolicitors(clientId: string): Promise<Solicitor[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getSolicitors", { params: { clientId } })
      .toPromise<ListResult<Solicitor>>();

    return result.items;
  }

  public async isFeeWaivedByDefault(mortgageId: string): Promise<boolean> {
    const result = await this.apiService
      .get(this.API_NAME, "isFeeWaivedByDefault", { params: { mortgageId } })
      .toPromise<Result<boolean>>();

    return result.value;
  }

  public async getHelpToBuyRegions(): Promise<HelpToBuyRegion[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getHelpToBuyRegions")
      .toPromise<ListResult<HelpToBuyRegion>>();

    return result.items;
  }

  public async setHelpToBuyStatus(
    mortgageId: string,
    data: HelpToBuy
  ): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "setHelpToBuyStatus", data, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async clearHelpToBuyStatus(mortgageId: string): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "clearHelpToBuyStatus", undefined, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async qualifyClient(
    mortgageId: string,
    offerQualification: OfferQualification
  ): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "qualifyClient", offerQualification, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async requestRequalification(
    mortgageId: string,
    requalificationRequest: RequalificationRequest
  ): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "requestRequalification", requalificationRequest, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async createComplianceHandover(
    mortgageId: string,
    complianceHandoverData: ComplianceHandoverData
  ): Promise<ComplianceHandoverData> {
    if (complianceHandoverData.endDate) {
      complianceHandoverData.endDate = toLocalDateISO(
        complianceHandoverData.endDate
      ) as any;
    }

    return this.apiService
      .post(this.API_NAME, "createComplianceHandover", complianceHandoverData, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async editComplianceHandover(
    mortgageId: string,
    handoverId: string,
    complianceHandoverData: ComplianceHandoverData
  ): Promise<ComplianceHandoverData> {
    const params = { mortgageId, handoverId };

    if (complianceHandoverData.endDate) {
      complianceHandoverData.endDate = toLocalDateISO(
        complianceHandoverData.endDate
      ) as any;
    }

    return this.apiService
      .post(this.API_NAME, "editComplianceHandover", complianceHandoverData, {
        params,
      })
      .toPromise();
  }

  public async viewClientSheet(
    mortgageId: string,
    handoverId: string
  ): Promise<ComplianceHandoverDataWithHistory> {
    const params = { mortgageId, handoverId };
    handoverId || delete params.handoverId;
    const result = await this.apiService
      .get(this.API_NAME, "viewClientSheet", { params })
      .toPromise<ComplianceHandoverDataWithHistory>();

    if (result.endDate) {
      result.endDate = moment(result.endDate).toDate();
    }

    return result;
  }

  public async approveComplianceHandover(
    mortgageId: string,
    handoverId: string,
    value: { value: string }
  ): Promise<void> {
    return this.apiService
      .post(this.API_NAME, "approveComplianceHandover", value, {
        params: { mortgageId, handoverId },
      })
      .toPromise();
  }

  public async undoComplianceHandoverApproval(
    mortgageId: string,
    handoverId: string
  ): Promise<ComplianceHandoverData> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "undoComplianceHandoverApproval", undefined, {
        params,
      })
      .toPromise();
  }

  public async changeSalesAdvisor(
    mortgageId: string,
    salesAdvisorId: string
  ): Promise<void> {
    const params = { mortgageId, salesAdvisorId };

    return this.apiService
      .post(this.API_NAME, "changeSalesAdvisor", undefined, { params })
      .toPromise();
  }

  public async changeLenderReference(
    mortgageId: string,
    handoverId: string,
    value: string
  ): Promise<void> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "changeLenderReference", { value }, { params })
      .toPromise();
  }

  public async setMortgageNotProceeding(
    mortgageId: string,
    journalWithReason: JournalWithReasonDTO
  ) {
    return this.apiService
      .post(this.API_NAME, "setMortgageNotProceeding", journalWithReason, {
        params: { mortgageId },
      })
      .toPromise();
  }

  public async reactivateMortgage(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "reactivateMortgage", undefined, { params })
      .toPromise();
  }

  public async getApplicantsWithCreditSearchOptOut(
    mortgageId: string
  ): Promise<CreditSearchOptOutDTO> {
    const params = { mortgageId };

    return this.apiService
      .get(this.API_NAME, "getApplicantsWithCreditSearchOptOut", { params })
      .toPromise();
  }

  public async saveCreditSearchOptOutOptions(
    mortgageId: string,
    creditSearchOptOut: CreditSearchOptOutDTO
  ): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(
        this.API_NAME,
        "saveCreditSearchOptOutOptions",
        creditSearchOptOut,
        { params }
      )
      .toPromise();
  }

  public async addRemortgage(
    remortgage: RemortgageDTO
  ): Promise<MortgageListItem> {
    return this.apiService
      .post(this.API_NAME, "addRemortgage", remortgage)
      .toPromise<MortgageListItem>();
  }

  public async switchClientCategory(
    mortgageId: string,
    switchClientCategory: SwitchClientCategoryDTO
  ): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "switchClientCategory", switchClientCategory, {
        params,
      })
      .toPromise();
  }

  public async getCreateApplicationInfo(
    mortgageId: string
  ): Promise<CreateApplicationInfoDTO> {
    const params = { mortgageId };

    return this.apiService
      .get(this.API_NAME, "getCreateApplicationInfo", { params })
      .toPromise();
  }

  public async getCommission(applicationId: string): Promise<CommissionDTO> {
    const params = { applicationId };

    return this.apiService
      .get(this.API_NAME, "getCommission", { params })
      .toPromise();
  }

  public async getNotProceedingReasons(
    mortgageId: string
  ): Promise<NotProceedingReasonsDTO> {
    const params = { mortgageId };

    return this.apiService
      .get(this.API_NAME, "getNotProceedingReasons", { params })
      .toPromise();
  }

  public async getMortgageHandovers(
    mortgageId: string
  ): Promise<MortgageDashboardHandover[]> {
    const params = { mortgageId };

    const result = await this.apiService
      .get(this.API_NAME, "getMortgageHandovers", { params })
      .toPromise<ListResult<MortgageDashboardHandover>>();

    return result.items;
  }

  public getImportedData(
    mortgageId: string
  ): Observable<MortgageImportedDataDTO> {
    const params = { mortgageId };

    return this.apiService.get<MortgageImportedDataDTO>(
      this.API_NAME,
      "getImportedData",
      { params }
    );
  }

  public editTargetCompletionDate(
    mortgageId: string,
    dto: EditTargetCompletionDateDTO
  ): Observable<void> {
    const params = { mortgageId };

    dto.targetCompletionDate = toLocalDateISO(dto.targetCompletionDate) as any;

    return this.apiService.post(
      this.API_NAME,
      "editTargetCompletionDate",
      dto,
      { params }
    );
  }

  public editTargetExchangeDate(
    mortgageId: string,
    dto: EditTargetExchangeDateDTO
  ): Observable<void> {
    const params = { mortgageId };

    dto.targetExchangeDate = toLocalDateISO(dto.targetExchangeDate) as any;

    return this.apiService.post(this.API_NAME, "editTargetExchangeDate", dto, {
      params,
    });
  }

  public viewNewClientSheet(
    mortgageId: string,
    handoverId: string
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService
      .get(this.API_NAME, "viewNewClientSheet", { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public viewNewOrLegacyClientSheet(
    mortgageId: string,
    handoverId: string
  ): Observable<NewOrLegacyComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService
      .get(this.API_NAME, "viewNewOrLegacyClientSheet", { params })
      .pipe(
        map((result) => {
          result.newHandover &&
            fixDatesInNewClientSheet(result.newHandover);
          return result;
        })
      );
  }


  public createNewComplianceHandover(
    mortgageId: string
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "createNewComplianceHandover", null, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverObjective(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverObjectiveDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverObjective", dto, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverObjectiveDraft(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverObjectiveDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverObjectiveDraft", dto, {
        params,
      })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverPreference(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverPreferenceDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverPreference", dto, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverPreferenceDraft(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverPreferenceDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverPreferenceDraft", dto, {
        params,
      })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverBudget(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverBudgetDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverBudget", dto, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverBudgetDraft(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverBudgetDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverBudgetDraft", dto, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverRecommended(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverRecommendedDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    if (dto.endDate) {
      dto.endDate = toLocalDateISO(dto.endDate) as any;
    }
    if (dto.illustrationDate) {
      dto.illustrationDate = toLocalDateISO(dto.illustrationDate) as any;
    }

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverRecommended", dto, { params })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public saveComplianceHandoverRecommendedDraft(
    mortgageId: string,
    handoverId: string,
    dto: NewComplianceHandoverRecommendedDTO
  ): Observable<NewComplianceHandoverViewDTO> {
    const params = { mortgageId, handoverId };

    if (dto.endDate) {
      dto.endDate = toLocalDateISO(dto.endDate) as any;
    }
    if (dto.illustrationDate) {
      dto.illustrationDate = toLocalDateISO(dto.illustrationDate) as any;
    }

    return this.apiService
      .post(this.API_NAME, "saveComplianceHandoverRecommendedDraft", dto, {
        params,
      })
      .pipe(map((result) => fixDatesInNewClientSheet(result)));
  }

  public completeHandover(
    mortgageId: string,
    handoverId: string
  ): Observable<void> {
    const params = { mortgageId, handoverId };

    return this.apiService.post(this.API_NAME, "completeHandover", null, {
      params,
    });
  }

  public getSuitabilityLetterPreview(
    mortgageId: string,
    handoverId?: string
  ): Observable<SuitabilityLetterDTO> {
    const params = { mortgageId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService.get(this.API_NAME, "getSuitabilityLetterPreview", {
      params,
    });
  }

  public sendSuitabilityLetter(
    mortgageId: string,
    handoverId?: string
  ): Observable<any> {
    const params = { mortgageId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService.post(this.API_NAME, "sendSuitabilityLetter", null, {
      params,
    });
  }

  public pushToMidas(type: PUSH_TO_MIDAS_TYPE, mortgageId: string): Observable<any> {
    const params = { type, mortgageId };
    return this.apiService.post(this.API_NAME, "triggerMidasPush", null, {
      params,
    });
  }

  public updateIntroducer(dto: UpdateIntroducerDto): Observable<any> {
    return this.apiService.post(this.API_NAME, "updateIntroducer", dto);
  }

  public getPropCodes(lenderId: string): Observable<GetPropCodesResult> {
    const params = { lenderId };

    return this.apiService.get(this.API_NAME, "getPropCodes", { params });
  }
}
